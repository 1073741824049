import { createSlice } from "@reduxjs/toolkit";

const proposalSlice = createSlice({
    name: "proposal",
    initialState: {
        proposals: [],
        
    },
    reducers: {
        setProposals: (state, action) => {
            state.proposals = action.payload;
        },
        addProposal: (state, action) => {
            state.proposals.push(action.payload);
        },
        updateProposal: (state, action) => {
            const { id, updatedData } = action.payload;
            const proposalIndex = state.proposals.findIndex(proposal => proposal.id === id);
            if (proposalIndex !== -1) {
                state.proposals[proposalIndex] = { ...state.proposals[proposalIndex], ...updatedData };
            }
        },
        deleteProposal: (state, action) => {
            const id = action.payload;
            state.proposals = state.proposals.filter(proposal => proposal.id !== id);
        },
    },
});

export const {
    setProposals,
    addProposal,
    updateProposal,
    deleteProposal
} = proposalSlice.actions;

export default proposalSlice.reducer;

export const selectProposals = (state) => state.proposal.proposals;
