import { createSlice } from "@reduxjs/toolkit";

const MpesaSlice = createSlice({
    name: "mpesa",
    initialState: {
        mpesa: [],
        failed: false,
        succes: false,
        pending: false,
    },
    reducers: {
        setMpesa: (state, action) => {
            state.mpesa = action.payload;
        },
        setSuccess: (state, action) => {
            state.succes = action.payload
            state.failed = false
            state.pending = false
        },
        setFailed: (state, action) => {
            state.failed = action.payload
            state.pending = false
            state.succes = false
        },
        setPendingPayenmnts: (state, action) => {
            state.pending = action.payload
            state.failed = false
            state.succes = false
        },
        setUpdateMpesa: (state, action) => {
            state.mpesa = { ...state.mpesa, ...action.payload };
        },

    },
});

export const {
    setMpesa,
    setFailed,
    setPendingPayenmnts,
    setSuccess,
    setUpdateMpesa,
} = MpesaSlice.actions;

export default MpesaSlice.reducer;

export const selectMpesa = (state) => state.mpesa.mpesa;
export const selectSuccess = (state) => state.mpesa.succes;
export const selectPending = (state) => state.mpesa.pending;
export const selectFailed = (state) => state.mpesa.failed;
