import { createSlice } from "@reduxjs/toolkit";

const payoutSlice = createSlice({
    name: "payout",
    initialState: {
        payouts: [],
        likes: [],
        isLoading: false
    },
    reducers: {
        // Set payouts data including comments and replies
        setpayouts: (state, action) => {
            state.payouts = action.payload;
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },

        // Set likes data
        setLikes: (state, action) => {
            state.likes = action.payload;
        },
        // Add a new payout
        addpayout: (state, action) => {
            state.payouts.push(action.payload);
        },
        // Update an existing payout
        updatepayout: (state, action) => {
            const { id, updatedData } = action.payload;
            const payoutIndex = state.payouts.findIndex(payout => payout.id === id);
            if (payoutIndex !== -1) {
                state.payouts[payoutIndex] = { ...state.payouts[payoutIndex], ...updatedData };
            }
        },
        // Delete a payout by ID
        deletepayout: (state, action) => {
            const id = action.payload;
            state.payouts = state.payouts.filter(payout => payout.id !== id);
        },


    },
});

export const {
    setpayouts,
    setLikes,
    addpayout,
    updatepayout,
    deletepayout,
    setLoading,

} = payoutSlice.actions;

export default payoutSlice.reducer;

// Selectors
export const selectpayouts = (state) => state.payout.payouts;
export const selectIsLoading = (state) => state.payout.isLoading;
export const selectLikes = (state) => state.payout.likes;
