import { createSlice } from "@reduxjs/toolkit";

const notificationSlice = createSlice({
    name: "notification",
    initialState: {
        notifications: [],
    },
    reducers: {
        setNotifications: (state, action) => {
            state.notifications = action.payload;
        },
        addNotification: (state, action) => {
            state.notifications.push(action.payload);
        },
        updateNotification: (state, action) => {
            const { id, updatedData } = action.payload;
            const notificationIndex = state.notifications.findIndex(notification => notification.id === id);
            if (notificationIndex !== -1) {
                state.notifications[notificationIndex] = { ...state.notifications[notificationIndex], ...updatedData };
            }
        },
        deleteNotification: (state, action) => {
            const id = action.payload;
            state.notifications = state.notifications.filter(notification => notification.id !== id);
        },
    },
});

export const {
    setNotifications,
    addNotification,
    updateNotification,
    deleteNotification,
} = notificationSlice.actions;

export default notificationSlice.reducer;

export const selectNotifications = (state) => state.notification.notifications;
