import { createSlice } from "@reduxjs/toolkit";

const paymentMethodSlice = createSlice({
    name: "paymentMethods",
    initialState: {
        paymentMethods: [],
    },
    reducers: {
        setPaymentMethods: (state, action) => {
            state.paymentMethods = action.payload;
        },

    },
});

export const {
    setPaymentMethods
} = paymentMethodSlice.actions;

export default paymentMethodSlice.reducer;

export const selectPaymentMethods = (state) => state.paymentMethods.paymentMethods;
