import { createSlice } from "@reduxjs/toolkit";

const messageSlice = createSlice({
    name: "message",
    initialState: {
        messages: [], // List of all chats with their messages
        selectedChat: null,
        loading: false
    },
    reducers: {
        setMessages: (state, action) => {
            state.messages = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        selectChat: (state, action) => {
            state.selectedChat = action.payload;
        },
        updateChatSidebar: (state, action) => {
            const newChat = action.payload;
            const newMessage = newChat.messages[0];

            // Check if the chat already exists based on receiverId or senderId
            const existingChatIndex = state.messages.findIndex(chat => {
                return chat.id == newMessage.receiverId || chat.id == newMessage.senderId;
            });

            if (existingChatIndex > -1) {
                // Chat exists, update it

                state.messages[existingChatIndex] = {
                    ...state.messages[existingChatIndex],
                    messages: [
                        ...state.messages[existingChatIndex].messages,
                        ...newChat.messages // Add new messages to the existing chat
                    ]
                };
            } else {
                state.messages.push(newChat);
            }
        },


        addMessage: (state, action) => {
            const newMessage = action.payload;

            // Update selectedChat if there is one
            if (state.selectedChat) {
                const updatedChat = {
                    ...state.selectedChat,
                    messages: [...state.selectedChat.messages, newMessage]
                };

                // Update the selected chat in the messages array
                state.messages = state.messages.map(chat =>
                    chat.id === state.selectedChat.id ? updatedChat : chat
                );

                // Update the selected chat in the state
                state.selectedChat = updatedChat;
            }
        },
        updateLastSeen: (state, action) => {
            const { id, lastSeen, online } = action.payload;

            const sidebarChatIndex = state?.messages?.findIndex(chat => chat.id === id);
            if (sidebarChatIndex > -1) {
                state.messages[sidebarChatIndex] = {
                    ...state.messages[sidebarChatIndex],
                    lastSeen,
                    online,
                };
            }
            state.selectedChat = {
                ...state.selectedChat,
                lastSeen,
                online
            }
        },
        markMessagesAsRead: (state, action) => {
            const { readMessages } = action.payload;

            state.messages.forEach(chat => {
                chat.messages.forEach(message => {
                    if (readMessages.some(unreadMessage => unreadMessage.id === message.id)) {
                        console.log(`Marking message as read in sidebarChats: ${message.id}`);
                        message.read = true;
                    }
                });
            });
        },
    },
});

export const {
    setMessages,
    setLoading,
    selectChat,
    addMessage,
    updateLastSeen,
    markMessagesAsRead,
    updateChatSidebar,
} = messageSlice.actions;

export default messageSlice.reducer;

export const selectMessages = (state) => state.message.messages;
export const selectLoading = (state) => state.message.loading;
export const selectSelectedChat = (state) => state.message.selectedChat;
