import { createSlice } from "@reduxjs/toolkit";

const walletSlice = createSlice({
    name: "wallet",
    initialState: {
        wallets: [],
    },
    reducers: {
        setWallets: (state, action) => {
            state.wallets = action.payload;
        },

    },
});

export const {
    setWallets
} = walletSlice.actions;

export default walletSlice.reducer;

export const selectWallets = (state) => state.wallet.wallets;
