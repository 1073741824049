import { createSlice } from "@reduxjs/toolkit";

const milestoneSlice = createSlice({
    name: "milestone", // Changed to lowercase to follow naming conventions
    initialState: {
        milestones: [], // Changed to lowercase for consistency
    },
    reducers: {
        setMilestones: (state, action) => {
            state.milestones = action.payload;
        },
        clearMilestones: (state) => {
            state.milestones = []; // Added clear function for resetting milestones
        },
    },
});

// Exporting actions
export const {
    setMilestones, // Adjusted to plural for clarity
    clearMilestones,
} = milestoneSlice.actions;

// Default export of the reducer
export default milestoneSlice.reducer;

// Selector to access milestones from the state
export const selectMilestones = (state) => state.milestone.milestones; // Changed to lowercase for consistency
