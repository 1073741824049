import { createSlice } from "@reduxjs/toolkit";

const postSlice = createSlice({
    name: "post",
    initialState: {
        posts: [],
        likes: [],
    },
    reducers: {
        // Set posts data including comments and replies
        setPosts: (state, action) => {
            state.posts = action.payload;
        },
        // Set likes data
        setLikes: (state, action) => {
            state.likes = action.payload;
        },
        // Add a new post
        addPost: (state, action) => {
            state.posts.push(action.payload);
        },
        // Update an existing post
        updatePost: (state, action) => {
            const { id, updatedData } = action.payload;
            const postIndex = state.posts.findIndex(post => post.id === id);
            if (postIndex !== -1) {
                state.posts[postIndex] = { ...state.posts[postIndex], ...updatedData };
            }
        },
        // Delete a post by ID
        deletePost: (state, action) => {
            const id = action.payload;
            state.posts = state.posts.filter(post => post.id !== id);
        },
        // Add a comment to a post
        setAddComment: (state, action) => {
            const { postId, comment } = action.payload;
            const postIndex = state.posts.findIndex(post => post.id === postId);
            if (postIndex !== -1) {
                state.posts[postIndex].comments.push(comment);
            }
        },
        // Add a reply to a comment
        setAddReply: (state, action) => {
            const { reply } = action.payload;
            console.log(reply);

            const postIndex = state.posts.findIndex(post => post.id === reply.postId);
            if (postIndex !== -1) {
                const commentIndex = state.posts[postIndex].comments.findIndex(comment => comment.id === reply.parentId);
                if (commentIndex !== -1) {
                    state.posts[postIndex].comments[commentIndex].replies.push(reply);
                }
            }
        }
        ,
        // Toggle like on a post and update the like count
        toggleLike: (state, action) => {
            const { postId, userId } = action.payload;
            const postIndex = state.posts.findIndex(post => post.id === postId);

            if (postIndex !== -1) {
                const post = state.posts[postIndex];
                const existingLikeIndex = post.postLikes.findIndex(like => like.userId === userId);

                if (existingLikeIndex !== -1) {
                    // If like exists, remove it (unlike) and decrement like count
                    post.postLikes.splice(existingLikeIndex, 1);
                    post.likes = Math.max(post.likes - 1, 0); // Decrement likes count
                } else {
                    // If like does not exist, add it (like) and increment like count
                    post.postLikes.push({ userId });
                    post.likes += 1; // Increment likes count
                }

                // Update the post in the state
                state.posts[postIndex] = { ...post };
            }
        }
    },
});

export const {
    setPosts,
    setLikes,
    addPost,
    updatePost,
    deletePost,
    setAddComment,
    setAddReply,
    toggleLike,
} = postSlice.actions;

export default postSlice.reducer;

// Selectors
export const selectPosts = (state) => state.post.posts;
export const selectLikes = (state) => state.post.likes;
