import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: "project",
    initialState: {
        projects: [],
        project: [],
        contractProject: [],
        ongoingProject: [], // Note: Fixed spelling to camelCase
        completedProject: [],
        selectedProject: {},
        viewDetails: false,
        searchTerm: "",
        currentPage: 1,
        filteredProjects: [],
    },
    reducers: {
        setProjects: (state, action) => {
            state.projects = action.payload;
        },
        setFilteredProjects: (state, action) => {
            state.filteredProjects = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
        setCompletedProjects: (state, action) => {
            state.completedProject = action.payload;
        },
        setViewDetails: (state, action) => {
            state.viewDetails = action.payload;
        },
        setOngoingProjects: (state, action) => {
            state.ongoingProject = action.payload;
        },
        setNewMilestone: (state, action) => {
            const { projectId, milestone } = action.payload; // Expecting projectId and milestone object
            console.log(projectId);
            
            // Map through the ongoing projects to create a new state
            state.ongoingProject = state.ongoingProject.map(project => {
                console.log(project.id); // Log the current project's ID
                if (project.id === projectId) {
                    return {
                        ...project,
                        milestones: [
                            ...project.milestones,
                            {
                                ...milestone,
                                payments: [] // Initialize payments as an empty array
                            }
                        ]
                    };
                }
                return project; // Return unchanged project
            });
        },
        
        setContractProjects: (state, action) => {
            state.contractProject = action.payload;
        },
        setselectedProject: (state, action) => {
            state.selectedProject = action.payload;
        },
        setProject: (state, action) => {
            state.project = action.payload;
        },
        addProject: (state, action) => {
            state.projects.push(action.payload);
        },
        updateProject: (state, action) => {
            const { id, updatedData } = action.payload;
            const projectIndex = state.projects.findIndex(project => project.id === id);
            if (projectIndex !== -1) {
                state.projects[projectIndex] = { ...state.projects[projectIndex], ...updatedData };
            }
        },
        deleteProject: (state, action) => {
            const id = action.payload;
            state.projects = state.projects.filter(project => project.id !== id);
        },
        deleteOngoingProject: (state, action) => {
            const id = action.payload;
            state.ongoingProject = state.ongoingProject.filter(project => project.id !== id);
        },
        toggleBookmarkInProject: (state, action) => {
            const { projectId, bookmark } = action.payload;

            // Create a new projects array to ensure immutability
            const updatedProjects = state.projects.map(project => {
                if (project.id === projectId) {
                    // Check if the bookmark already exists
                    const bookmarkExists = project.bookmarks.some(b => JSON.stringify(b) === JSON.stringify(bookmark));

                    if (bookmarkExists) {
                        // Reset bookmarks to an empty array
                        return { ...project, bookmarks: [] };
                    } else {
                        // Add new bookmark
                        return { ...project, bookmarks: [...project.bookmarks, bookmark] };
                    }
                }
                return project;
            });

            // Return the new state with updated projects
            return { ...state, projects: updatedProjects };
        },
        setBookmarksForProjects: (state, action) => {
            const { projectId, bookmarks } = action.payload;

            // Update the bookmarks for a specific project
            const project = state.projects.find(project => project.id === projectId);
            if (project) {
                project.bookmarks = bookmarks;
            }
        },
        setVerifyContract: (state, action) => {
            const id = action.payload;
            state.contractProject = state.contractProject.filter(project => project.id !== id);
        },
    },
});

export const {
    setProjects,
    setProject,
    setOngoingProjects,
    setCompletedProjects,
    addProject,
    setSearchTerm,
    setBookmarksForProjects,
    toggleBookmarkInProject,
    updateProject,
    deleteProject,
    setContractProjects,
    setVerifyContract,
    setViewDetails,
    deleteOngoingProject,
    setselectedProject,
    setFilteredProjects,
    setCurrentPage,
    setNewMilestone, // Don't forget to export the newMilestone action
} = authSlice.actions;

export default authSlice.reducer;

export const selectViewDetails = (state) => state.project.viewDetails;
export const selectOngoingProject = (state) => state.project.ongoingProject;
export const selectProjects = (state) => state.project.projects;
export const selectCompletedProjects = (state) => state.project.completedProject;
export const selectContractProjects = (state) => state.project.contractProject;
export const selectProject = (state) => state.project.project;
export const selectSelectedProject = (state) => state.project.selectedProject;
export const selectSearchTerm = (state) => state.project.searchTerm;
export const selectFilteredProject = (state) => state.project.filteredProjects;
export const selectCurrentPage = (state) => state.project.currentPage;
