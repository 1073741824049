import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: "freelancer",
    initialState: {
        freelancers: [],
    },
    reducers: {
        setFreelancer: (state, action) => {
            state.freelancers = action.payload;
        },

    },
});

export const {
    setFreelancer
} = authSlice.actions;

export default authSlice.reducer;

export const selectFreelancer = (state) => state.freelancer.freelancers;
