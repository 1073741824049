import { createSlice } from "@reduxjs/toolkit";

const clientSlice = createSlice({
    name: "client",
    initialState: {
        clients: [],
    },
    reducers: {
        setClient: (state, action) => {
            state.clients = action.payload;
        },

    },
});

export const {
    setClient
} = clientSlice.actions;

export default clientSlice.reducer;

export const selectClient = (state) => state.client.clients;
