import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: "review",
    initialState: {
        reviews: [],
    },
    reducers: {
        setReviews: (state, action) => {
            state.reviews = action.payload;
        },

    },
});

export const {
    setReviews
} = authSlice.actions;

export default authSlice.reducer;

export const selectReviews = (state) => state.review.reviews;
