import { createSlice } from "@reduxjs/toolkit";

const categorySlice = createSlice({
    name: "category",
    initialState: {
        categories: [],
        loading: false
    },
    reducers: {
        setCategory: (state, action) => {
            state.categories = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        }
    },
});


export const {
    setCategory,
    setLoading,
} = categorySlice.actions;

export default categorySlice.reducer;

export const selectCategory = (state) => state.category.categories;
export const selectLoading = (state) => state.category.loading;
