// src/slices/membershipSlice.js
import { createSlice } from "@reduxjs/toolkit";

const membershipSlice = createSlice({
    name: "membership",
    initialState: {
        membership: [],
        userMembership: [],
        status: null,
        error: null,
        exchangeRate: [],
    },
    reducers: {
        setUserMembership: (state, action) => {
            state.userMembership = action.payload;
        },
        setExchangeRate: (state, action) => {
            state.exchangeRate = action.payload
        },
        addUserMembership: (state, action) => {
            state.userMembership.push(action.payload);
        },
        updateUserMembership: (state, action) => {
            const { id, updatedData } = action.payload;
            const membershipIndex = state.userMembership.findIndex(m => m.id === id);  // Updated to use userMembership
            if (membershipIndex !== -1) {
                state.userMembership[membershipIndex] = { ...state.userMembership[membershipIndex], ...updatedData };  // Updated to use userMembership
            }
        },
        setMembership: (state, action) => {
            state.membership = action.payload;
        },
        addMembership: (state, action) => {
            state.membership.push(action.payload);
        },
        updateMembership: (state, action) => {
            const { id, updatedData } = action.payload;
            const membershipIndex = state.membership.findIndex(m => m.id === id);
            if (membershipIndex !== -1) {
                state.membership[membershipIndex] = { ...state.membership[membershipIndex], ...updatedData };
            }
        },
        deleteMembership: (state, action) => {
            const id = action.payload;
            state.membership = state.membership.filter(m => m.id !== id);
        },
        setStatus: (state, action) => {
            state.status = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
    },
});

export const {
    setMembership,
    addMembership,
    updateMembership,
    deleteMembership,
    setUserMembership,
    addUserMembership,
    updateUserMembership,
    setStatus,
    setExchangeRate,
    setError,
} = membershipSlice.actions;

export default membershipSlice.reducer;

export const selectExchangeRate = (state) => state.membership.exchangeRate;
export const selectMembership = (state) => state.membership.membership;
export const selectUserMembership = (state) => state.membership.userMembership;  // Added selector
export const selectMembershipStatus = (state) => state.membership.status;
export const selectMembershipError = (state) => state.membership.error;
